// extracted by mini-css-extract-plugin
export var aboutUsSection = "AboutUsSection-module--about-us-section--rP-pN";
export var body = "AboutUsSection-module--body--Zqbhs";
export var borderSection = "AboutUsSection-module--border-section--tMBhV";
export var collage = "AboutUsSection-module--collage--kR+st";
export var content = "AboutUsSection-module--content--B6aZ7";
export var horizontalTextLeft = "AboutUsSection-module--horizontal-text-left--lPFYo";
export var horizontalTextRight = "AboutUsSection-module--horizontal-text-right--dxNCP";
export var imageLeft = "AboutUsSection-module--image-left---z4lo";
export var imageLeftBottom = "AboutUsSection-module--image-left-bottom--MHLBh";
export var imageLeftTop = "AboutUsSection-module--image-left-top--H4Sl5";
export var imageRight = "AboutUsSection-module--image-right--i2xSX";
export var imageRightCenter = "AboutUsSection-module--image-right-center--bmNWP";
export var imageText = "AboutUsSection-module--image-text--wTCap";
export var verticalText = "AboutUsSection-module--vertical-text--hItFV";
import React, { useEffect } from "react";
import gsap from "gsap";

import * as styles from "./ScrollDownButton.module.scss";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const ScrollDownButton = React.forwardRef((props, ref) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  const clickHandler = (event) => {
    const position = event.target.getBoundingClientRect();
    const targetY = position.bottom + (window.pageYOffset || document.documentElement.scrollTop);
    gsap.to(window, {
      scrollTo: targetY,
      ease: "power2.inOut",
      duration: 1,
    });
  };

  return (
    <div ref={ref} className={[styles.wrapper, props.className].join(" ")}>
      <button className={styles.icon} onClick={clickHandler}>
        <span className={styles.disc}></span>
      </button>
    </div>
  );
});

export default ScrollDownButton;

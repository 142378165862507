import gsap from "gsap";
import { useEffect } from "react";

const useHomeAnimation = (props) => {
  const { firstStep, secondStep, thirdStep, navbarLogo } = props;

  useEffect(() => {
    const allElements = [...firstStep, ...secondStep, ...thirdStep];

    allElements.forEach((item) => {
      item.current.style.opacity = 0;
    });

    let anchor = null;
    if (navbarLogo) {
      anchor = navbarLogo.current.querySelector("a");
      anchor.style.top = "110%";
    }

    const timeline = new gsap.timeline({
      repeat: 0,
      paused: true,
      onComplete: function () {
        document.querySelector("html").classList.remove("html-first-load");
      },
    });

    if (firstStep.length > 0) {
      timeline.to(
        firstStep.map((item) => item.current),
        0.5,
        { opacity: 0.99 }
      );
    }

    if (secondStep.length > 0) {
      timeline.to(
        secondStep.map((item) => item.current),
        0.5,
        {
          opacity: 0,
          onComplete: () => {
            secondStep.forEach((item) => {
              if (item.current) {
                item.current.style.display = "none";
              }
            });
          },
        },
        "+=3"
      );
    }

    if (thirdStep.length > 0) {
      timeline.to(
        thirdStep.map((item) => item.current),
        1.5,
        { opacity: 1 },
        "-=0.5"
      );
    }

    if (anchor) {
      timeline.to(anchor, 1.5, { top: 0 }, "-=1.5");
    }

    const timeout = setTimeout(function () {
      timeline.play();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [firstStep, secondStep, thirdStep, navbarLogo]);
};

export default useHomeAnimation;

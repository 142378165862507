import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import DefaultFeaturedMediaImage from "../DefaultFeaturedMediaImage";
import FeaturedMediaImage from "../FeaturedMediaImage";
import FeaturedMediaVideo from "../FeaturedMediaVideo";
import { StaticImage } from "gatsby-plugin-image";
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton";
import useParallax from "../../utils/useParallax";
import useHomeAnimation from "../../utils/useHomeAnimation";
import NavbarContext from "../../store/navbar-context";

const Head = (props) => {
  const navbarContext = useContext(NavbarContext);
  const parallaxParent = useRef(null);
  const parallaxChild = useRef(null);
  const scrollButtonRef = useRef(null);
  const refCenterLogo = useRef(null);

  useParallax({
    parent: parallaxParent,
    child: parallaxChild,
  });

  useHomeAnimation({
    firstStep: [parallaxParent, refCenterLogo],
    secondStep: [refCenterLogo],
    thirdStep: [scrollButtonRef, navbarContext.navbarMenuRef],
    navbarLogo: navbarContext.navbarLogoRef
  });

  if (!props.media) {
    return null;
  }

  const renderMedia = (media, index) => {
    switch (media.__typename) {
      case "WpPage_Featuredmedia_FeaturedMedia_FeaturedImage":
        const { image } = media;
        if (image) {
          return (
            <FeaturedMediaImage
              image={image}
              fullHeightOnMobile={true}
              key={index}
            />
          );
        } else {
          return (
            <DefaultFeaturedMediaImage fullHeightOnMobile={true} key={index} />
          );
        }
      case "WpPage_Featuredmedia_FeaturedMedia_FeaturedVideo":
        const { mobileVideoUrl, desktopVideoUrl } = media;

        if (mobileVideoUrl && desktopVideoUrl) {
          return (
            <FeaturedMediaVideo
              mobileVideoUrl={mobileVideoUrl}
              desktopVideoUrl={desktopVideoUrl}
              fullHeightOnMobile={true}
              key={index}
            />
          );
        } else {
          return <DefaultFeaturedMediaImage />;
        }
      default:
        return null;
    }
  };

  return (
    <div>
      <div
        className="hero"
        style={{ overflow: "hidden", position: "relative" }}
        ref={parallaxParent}
      >
        <div ref={parallaxChild}>{props.media.map(renderMedia)}</div>
        <ScrollDownButton ref={scrollButtonRef} className="scroll-down-arrow-wrap" />
      </div>
      <div className="centered-logo" ref={refCenterLogo}>
        <div className="centered-logo-inner-wrap">
          <div className="centered-logo-image-wrap">
            <StaticImage
              src="../../assets/images/logo.png"
              alt="Aspect"
              loading="eager"
              height={60}
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Head.propTypes = {
  media: PropTypes.array.isRequired,
};

export default Head;

import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const useParallax = ({ parent, child }) => {
  useEffect(() => {
    if (!parent.current || !child.current) {
      return
    }

    const parentElement = parent.current;
    const childElement = child.current;

    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: parentElement,
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });

    const depth = -0.5;
    const movement = -(childElement.offsetHeight * depth);
    tl.to(childElement, { y: movement, ease: "none" }, 0);
  }, [parent, child]);
};

export default useParallax;

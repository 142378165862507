import React, { useState } from "react";
import PropTypes from "prop-types";
import { Localise, Keys } from "../../utils/translations";
import PostGridItem from "./PostGridItem";

import * as styles from "./PostGrid.module.scss";

const PostGrid = (props) => {
    const postsPerPage = 3;
    const {
        posts,
        pageContext: { languageCode },
    } = props;

    const [list, setList] = useState([...props.posts.slice(0, postsPerPage)]);

    const hasMore = list.length < posts.length;

    const loadMore = () => {
        if (hasMore) {
            const newItems = posts.slice(
                list.length,
                list.length + postsPerPage
            );
            setList([...list, ...newItems]);
        }
    };

    return (
        <div className={styles.postGrid}>
            <hr />
            <div className="grid-container fluid">
                <div className="grid-x grid-margin-x">
                    {list.map((post) => {
                        return (
                            <PostGridItem
                                key={post.id}
                                className="cell medium-6 large-4"
                                image={post.featuredImage?.node.localFile}
                                date={post.date}
                                title={post.title}
                                excerpt={post.excerpt}
                                uri={post.uri}
                            />
                        );
                    })}
                </div>
            </div>
            {hasMore && (
                <div className="grid-container fluid text-center">
                    <div className="grid-x grid-margin-x">
                        <div className="cell">
                            <button
                                type="button"
                                className={[
                                    styles.button,
                                    "button hollow",
                                ].join(" ")}
                                onClick={loadMore}
                            >
                                {Localise(Keys.MORE, languageCode)}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

PostGrid.propTypes = {
    title: PropTypes.string,
    posts: PropTypes.array.isRequired,
};

export default PostGrid;

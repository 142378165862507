import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import * as styles from "./AboutUsSection.module.scss";

const AboutUsSection = (props) => {
    const imageLeftTop = getImage(props.image1.localFile);
    const imageLeftBottom = getImage(props.image2.localFile);
    const imageRight = getImage(props.image3.localFile);

    return (
        <div className={styles.aboutUsSection} id={props.id}>
            <div className="grid-container fluid">
                <div className="grid-x grid-margin-x">
                    <div className="cell medium-6">
                        <div className={styles.content}>
                            <div className="js-slide-reveal-container">
                                <div className="h1 js-slide-reveal">
                                    {props.title}
                                </div>
                                </div>
                            <div className="js-reveal-container">
                                <div
                                    className={[styles.body, "js-reveal"].join(" ")}
                                    dangerouslySetInnerHTML={{
                                        __html: props.description,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="cell medium-6">
                        <div className={styles.collage}>
                            <div className={styles.borderSection}>
                                <div
                                    className={[
                                        styles.verticalText,
                                        "secondary-font",
                                    ].join(" ")}
                                >
                                    {props.image1.altText}
                                </div>
                                <div
                                    className={[
                                        styles.horizontalTextLeft,
                                        "secondary-font",
                                    ].join(" ")}
                                >
                                    {props.image2.altText}
                                </div>
                                <div
                                    className={[
                                        styles.horizontalTextRight,
                                        "secondary-font",
                                    ].join(" ")}
                                >
                                    {props.image3.altText}
                                </div>
                            </div>
                            <div className={styles.imageLeft}>
                                <div className={styles.imageLeftTop}>
                                    <div
                                        className={[
                                            styles.imageText,
                                            "secondary-font",
                                        ].join(" ")}
                                    >
                                        {props.image1.altText}
                                    </div>
                                    <div className="js-reveal-image">
                                        <GatsbyImage
                                            image={imageLeftTop}
                                            alt={props.image1.altText}
                                            />
                                    </div>
                                </div>
                                <div className={styles.imageLeftBottom}>
                                    <div
                                        className={[
                                            styles.imageText,
                                            "secondary-font",
                                        ].join(" ")}
                                    >
                                        {props.image2.altText}
                                    </div>
                                    <div className="js-reveal-image">
                                        <GatsbyImage
                                            image={imageLeftBottom}
                                            alt={props.image2.altText}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.imageRight}>
                                <div className={styles.imageRightCenter}>
                                    <div
                                        className={[
                                            styles.imageText,
                                            "secondary-font",
                                        ].join(" ")}
                                    >
                                        {props.image3.altText}
                                    </div>
                                    <div className="js-reveal-image">
                                        <GatsbyImage
                                            image={imageRight}
                                            alt={props.image3.altText}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AboutUsSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    image1: PropTypes.object.isRequired,
    image2: PropTypes.object.isRequired,
    image3: PropTypes.object.isRequired,
};

export default AboutUsSection;

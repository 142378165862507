import React from "react";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import FullWidthLayout from "../PostContent/FullWidthLayout";
import TwoColumnLayout from "../PostContent/TwoColumnLayout";
// import { Localise, Keys } from "../../utils/translations";
import * as styles from "./ShortArticle.module.scss";

const ShortArticle = (props) => {
  const { htmlId, pageContext, post } = props;

  const { date, postContent } = post;

  const hasContentSectionOnLeft = (data) => {
    const { column1Content } = data;

    for (let index = 0; index < column1Content?.length; index++) {
      if (
        column1Content[index].__typename ===
        "WpPost_Postcontent_Layout_TwoColumnLayout_Column1Content_ContentEditor"
      ) {
        return true;
      }
    }

    return false;
  };

  let firstContentSectionRendered = false;

  return (
    <div className={styles.shortArticle} id={htmlId}>
      {postContent.layout.map((layout, index) => {
        const { __typename: type } = layout;
        switch (type) {
          case "WpPost_Postcontent_Layout_FullWidthLayout":
            return (
              <FullWidthLayout
                key={index}
                content={layout}
                pageContext={pageContext}
              />
            );
          case "WpPost_Postcontent_Layout_TwoColumnLayout":
            let isFirstContentSection = false;

            if (!firstContentSectionRendered) {
              isFirstContentSection = hasContentSectionOnLeft(layout);
              firstContentSectionRendered = true;
            }

            return (
              <TwoColumnLayout
                key={index}
                content={layout}
                date={date}
                isFirstContentSection={isFirstContentSection}
                isHomeLayout={true}
                pageContext={pageContext}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

ShortArticle.propTypes = {
  htmlId: PropTypes.string,
  post: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ShortArticle;

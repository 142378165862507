import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Head from '../components/Head/Head';
import AboutUsSection from '../components/AboutUsSection';
import ShortArticle from '../components/ShortArticle/ShortArticle';
import PostGrid from '../components/PostGrid/PostGrid';
import ArtArchiveSection from '../components/Home/ArtArchiveSection';

import HomePage from '../components/HomePage';
import YoastSeo from '../components/YoastSeo';
// import PropTypes from "prop-types";

const home = (props) => {
  const { data, pageContext } = props;

  const {
    homePage,
    aboutUsPage,
    stickyPost,
    latestPosts: { nodes: latestPosts },
    posts: { nodes: posts },
    artArchive: { nodes: artArchivePages },
  } = data;

  const renderSinglePost = () => {
    var post = null;

    if (stickyPost) {
      post = stickyPost;
    } else if (latestPosts && latestPosts.length > 0) {
      post = latestPosts[0];
    }

    if (post) {
      return <ShortArticle post={post} htmlId="journal" pageContext={pageContext} />;
    }

    return null;
  };

  return (
    <HomePage>
      <Layout
        bodyClass={`page-template-default page page-id-home wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
        pageContext={pageContext}
      >
        <YoastSeo
          lang={pageContext.languageCode.toLowerCase()}
          {...homePage.seo}
          uri={homePage.uri}
        />
        {/* <Seo
          lang={pageContext.languageCode.toLowerCase()}
          title={homePage.title}
          description={homePage.seo.metaDesc}
          socialImage={homePage.featuredImage?.node}
          uri={homePage.uri}
          yoastSchema={homePage.seo.schema.raw}
        /> */}

        <Head media={homePage.featuredMedia?.featuredMedia} />

        {aboutUsPage && (
          <AboutUsSection
            id="about-us"
            title={aboutUsPage.title}
            description={aboutUsPage.aboutUsContent.aboutDescription}
            image1={aboutUsPage.aboutUsContent.image1}
            image2={aboutUsPage.aboutUsContent.image2}
            image3={aboutUsPage.aboutUsContent.image3}
            horizontalTextLeft={aboutUsPage.aboutUsContent.horizontalImageText}
            horizontalTextRight={aboutUsPage.aboutUsContent.horizontalImageTextRight}
            verticalText={aboutUsPage.aboutUsContent.verticalImageText}
          />
        )}
        {renderSinglePost()}
        {posts && <PostGrid posts={posts} pageContext={pageContext} />}
        {artArchivePages.length && <ArtArchiveSection page={artArchivePages[0]} />}
      </Layout>
    </HomePage>
  );
};

home.propTypes = {};

export const query = graphql`
  query ($languageCode: WpLanguageCodeEnum) {
    homePage: wpPage(slug: { eq: "home" }, language: { code: { eq: $languageCode } }) {
      id
      uri
      title
      seo {
        ...YoastSEOContent
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
          }
          mediaDetails {
            width
            height
          }
          altText
        }
      }
      featuredMedia {
        enableParallax
        featuredMedia {
          __typename
          ... on WpPage_Featuredmedia_FeaturedMedia_FeaturedImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
          }
          ... on WpPage_Featuredmedia_FeaturedMedia_FeaturedVideo {
            mobileVideoUrl
            desktopVideoUrl
          }
        }
      }
    }
    aboutUsPage: wpPage(slug: { eq: "about-us" }, language: { code: { eq: $languageCode } }) {
      id
      title
      slug
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      aboutUsContent {
        aboutDescription
        image1 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          altText
        }
        image2 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          altText
        }
        image3 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          altText
        }
      }
    }
    stickyPost: wpPost(isSticky: { eq: true }, language: { code: { eq: $languageCode } }) {
      ...PostContent
    }
    latestPosts: allWpPost(
      filter: { language: { code: { eq: $languageCode } } }
      sort: { fields: date, order: DESC }
      limit: 1
    ) {
      nodes {
        ...PostContent
      }
    }
    posts: allWpPost(
      filter: { language: { code: { eq: $languageCode } }, isSticky: { eq: false } }
    ) {
      nodes {
        id
        date(formatString: "DD / MM / YYYY")
        title
        excerpt
        uri
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
    artArchive: allWpPage(
      limit: 1
      filter: {
        template: { templateName: { eq: "Art Archive" } }
        language: { code: { eq: $languageCode } }
      }
    ) {
      nodes {
        id
        title
        uri
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;

export default home;

// import { useGsapReveal } from "../utils/useGsapReveal";
import React from "react";
const HomePage = (props) => {
    // const gsap = useGsapReveal();
    return (
        <>
            {props.children}
        </>
    );
}

export default HomePage;
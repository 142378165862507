import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import React from 'react';
import * as styles from './ArtArchiveSection.module.scss';

const ArtArchiveSection = (props) => {
    const placeholder = useStaticQuery(graphql`
        query ArtArchivePlaceholderQuery {
            artArchivePlaceholder: file(relativePath: { eq: "placeholders/art-archive-placeholder.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `)
    
    const { page } = props;
    const renderImage = () => {
        const sectionImage = page.featuredImage?.node?.localFile ? page.featuredImage.node.localFile : placeholder.artArchivePlaceholder;
        return (
        <GatsbyImage className={styles.artArchiveImage} image={getImage(sectionImage)} alt={page.title} />
        );
        
    }

    return (
        <div className={[styles.artArchiveSection, 'js-reveal-container'].join(' ')}>
            <Link to={page.uri} className={[styles.artArchiveLink, 'js-reveal-image'].join(' ')}>
                <div className={styles.titleWrap}>
                    <h1>{page.title}</h1>
                </div>
                {renderImage()}
            </Link>
        </div>
    )
}

export default ArtArchiveSection;